.landing-container {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3.125rem auto;
}
.chic-logo {
  height: 75vmin;
  pointer-events: none;
  font-family: var(--dancing-script);
}
.chic-sm-logo {
  height: 11vmin;
  pointer-events: none;
}
.chic-sub {
  font-family: var(--dancing-script);
  font: 1em;
  /* color: white; */
  color: var(--chic-pale);
  font-weight: 700;
}
/* .chic-sm-sub {
  font-size: 0.8375em;
  font-family: var(--dancing-script);
  color: var(--chic-pale);
} */
.logo-row {
  background: linear-gradient(145deg, #0e0e0e, #101010);
  box-shadow: 5px 5px 10px #282828, -5px -5px 10px #363636;
}

@media (prefers-reduced-motion: no-preference) {
  .chic-logo {
    animation: Chic-logo-spin infinite 10s linear;
  }
  .chic-sm-logo {
    animation: Chic-logo-spin infinite 10s linear;
  }
  .chic-sub {
    animation: Chic-sub-spin infinite 10s linear;
  }
  p.chic-sm-sub {
    animation: Chic-sub-spin infinite 10s linear;
  }
}

@keyframes Chic-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}

@keyframes Chic-sub-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}
