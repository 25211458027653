.about-row {
  background: linear-gradient(145deg, #0e0e0e, #101010);
  border: 5px double var(--chic-pale);
  box-shadow: 0 0 10px silver;
}
.content {
  font-size: 1.1em;
}

.chic-sm-text {
  font-family: var(--dancing-script);
  font-size: 1em;
}
