.policy-row {
  background: linear-gradient(145deg, #0e0e0e, #101010);
  border: 5px double var(--chic-pale);
  box-shadow: 0 0 10px silver;
}

.policy-holder {
  background: rgb(232, 221, 251);
  border: 4px solid #646464;
}
