.service-row {
  background: linear-gradient(145deg, #0e0e0e, #101010);
  box-shadow: inset 6px 6px 12px #585858, inset -6px -6px 12px #707070;
  border: 5px double var(--chic-pale);
}

.service-holder {
  background: rgb(232, 221, 251);
  border: 4px solid #646464;
}

.service-type {
  font-weight: 700;
}

.service-text {
  font-weight: 500;
  font-size: 1em;
  /* wrap the text if neeeded */
  white-space: pre-wrap;
}
