.site-footer {
  /* background-image: linear-gradient(
      335deg,
      var(--brick-dk-black) 23px,
      transparent 23px
    ),
    linear-gradient(155deg, var(--brick-lt-black) 23px, transparent 23px),
    linear-gradient(335deg, var(--brick-dk-black) 23px, transparent 23px),
    linear-gradient(155deg, var(--brick-lt-black) 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 26px 31px, 31px 6px;
  box-shadow: 5px 5px 10px #282828, -5px -5px 10px #363636;
  color: white; */

  background-image: linear-gradient(335deg, #0f0f0f 23px, transparent 23px),
    linear-gradient(155deg, #000000 23px, transparent 23px),
    linear-gradient(335deg, rgb(15, 15, 15) 23px, transparent 23px),
    linear-gradient(155deg, #060404 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 26px 31px, 31px 6px;
  box-shadow: 5px 5px 10px #282828, -5px -5px 10px #363636;
  color: white;
}

.footer-wrapper {
  padding: 0;
  color: #eeeeee;
  background-color: #646464;
}

.footer-row {
  background: linear-gradient(145deg, #0e0e0e, #101010);
  box-shadow: 5px 5px 3px #060606, -5px -5px 3px #181818;
}

/* footer icons */
.fa-instagram,
.fa-facebook {
  color: #eeeeee;
  font-size: 1.65em;
}

.btn-social-icon:focus,
.btn-social-icon:active,
.btn-social-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.ft-social:hover,
.ft-social:focus,
.ft-social:active {
  color: var(--chic-pale) !important;
  transform: scale(1.25);
  border: none;
  box-shadow: none;
}

/* site map */
li {
  list-style: none;
}
.ft-link {
  color: #ffffff;
  text-decoration: none;
}
.ft-link.active,
.ft-link:hover,
.ft-link:focus {
  color: var(--chic-pale) !important;
}

/* footer table */

tr {
  font-size: 0.975em;
  border: 1px solid #dee2e6;
}
td {
  border-left: 1px solid #dee2e6;
  background: rgba(30, 23, 36, 0.996);
}
td:nth-child(even) {
  border-right: 1px solid #dee2e6;
}
tr:hover {
  opacity: 100%;
  color: var(--chic-pale) !important;
  font-weight: bold;
}
.active {
  text-decoration: none;
}
.copyright {
  font-size: 0.8em;
  color: #ffffff;
  text-decoration: none;
}
