.hdr-social:hover,
.hdr-social:focus,
.hdr-book:hover,
.hdr-book:focus { 
  color: var(--chic-lavender);
}
.hdr-banner-link {
  height: 100%;
  border-radius: 0;
}
.hdr-banner {
  background: var(--chic-pale);
}
.hdr-book{
  border: 1px solid #ffffff;
  border-radius: 10px;
}
.hdr-book:hover,
.hdr-book:focus {
  background: var(--chic-lavender);
  color: var(--chic-pale);
}

.banner-contact:hover,
.banner-contact:focus {
  color: var(--chic-lavender);
}
.fa .fa-envelope:hover,
.fa .fa-envelope:focus {
  color: var(--chic-lavender);
}

.header-logo {
  background: transparent;
  height: 50px;
}
.chic-sm-sub {
  font-size: 0.8375em;
  font-family: var(--dancing-script);
  color: var(--chic-pale);
}

.nav-wrapper {
  background-image: linear-gradient(335deg, #0f0f0f 23px, transparent 23px),
    linear-gradient(155deg, #000000 23px, transparent 23px),
    linear-gradient(335deg, rgb(15, 15, 15) 23px, transparent 23px),
    linear-gradient(155deg, #060404 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 26px 31px, 31px 6px;
  box-shadow: 5px 5px 10px #282828, -5px -5px 10px #363636;
  padding: 0;
  color: #eeeeee;
  background-color: #646464;
}

.chicNav {
  background: linear-gradient(145deg, #0e0e0e, #101010);
  box-shadow: 5px 5px 3px #060606, -5px -5px 3px #181818;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  border: 3px double transparent;
}


.navbar-toggler {
  color: #ffffff !important;
  background: none;
  border: none !important;
  border-radius: 0;
  width: 75px;
  height: auto;
}

.dropdown-menu {
  width: auto;
  background: linear-gradient(145deg, #0e0e0e, #101010);
  box-shadow: 5px 5px 3px #060606, -5px -5px 3px #181818;
  border: 3px double var(--chic-pale);
}
.dropdown a {
  color: #ffffff !important;
}

.dropdown-item:hover {
  color: var(--nyc-black);
  background: transparent !important;
}

.dropdown.show a .dropdown-toggle::after {
  color: var(--chic-lavendar) !important;
}

/* .dropdown a .dropdown-toggle::after {
  color: #ffffff !important;
} */
.navbar-nav .nav-link.active,
.nav-link:hover,
.book-now:visited,
.dropdown-toggle.active,
.navbar-nav .show > .nav-link {
  color: var(--chic-pale) !important;
}
.hdr-link:hover,
.book-now:hover {
  border: 3px double var(--chic-pale) !important;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.toggler-icon:hover {
  color: var(--chic-pale);
}

.breadcrumb-item {
  color: var(--chic-pale);
  font-weight: 700;
}
.breadcrumb-item > a {
  /* color: #000; */
  text-decoration: none;
}
ol.breadcrumb {
  margin: 8px 0 0 0;
}

li.active.breadcrumb-item::before,
li.active.breadcrumb-item {
  color: var(--chic-pale);
  font-weight: 600;
}
