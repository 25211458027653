.salon-img-wrapper,
.carousel-img {
  /* background: #2e2d21;
  box-shadow: 26px 26px 25px #28281d, -26px -26px 25px #343225; */
  /* background: linear-gradient(145deg, #0e0e0e, #101010); */
  /* box-shadow: 5px 5px 3px #060606, -5px -5px 3px #181818; */
  box-shadow: inset 6px 6px 12px #585858, inset -6px -6px 12px #707070;
  border: 4px solid #646464;
}

.salon-img-wrapper {
  /* background: #2e2d21;
  box-shadow: 26px 26px 25px #28281d, -26px -26px 25px #343225; */
  background: #fef5f5;
  /* box-shadow: 5px 5px 3px #060606, -5px -5px 3px #181818; */
  box-shadow: inset 6px 6px 12px #585858, inset -6px -6px 12px #707070;
  border: 4px solid #646464;
}
.carousel-container {
  width: 100vw;
  background: black;
  /* max-height: 30vh; */
}
.carousel-control-next,
.carousel-control-prev {
  fill: var(--chic-pale) !important;
}

.about-img {
  height: 300px;
  width: auto;
}

@media screen and (min-width: 475px) {
  .about-img {
    height: 400px;
  }
}
@media screen and (min-width: 576px) {
  .about-img {
    height: 500px;
  }
}
@media screen and (min-width: 992px) {
  .about-img {
    height: 600px;
  }
}
