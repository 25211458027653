:root {
  --dancing-script: "Dancing Script", cursive;
  --urbanist: "Urbanist", sans-serif;
  /* like noto */
  --noto-sans-display: "Noto Sans Display", sans-serif;
  /* like prata */
  --prata: "Prata", serif;
  /* maybe */
  --lato: "Lato", sans-serif;
  /* really like */
  --gotu: "Gotu", sans-serif;
  /* maybe */
  --marcellus: "Marcellus", serif;
  /* maybe */
  --dm-serif-display: "DM Serif Display", serif;
  /*  good for ' in cmc name otherwise no */
  --della-respira: "Della Respira", serif;
  /*  its ok */
  --raleway: "Raleway", cursive;
  --montserrant: "Montserrat", sans-serif;
  --nyc-black: #000000;
  --nyc-gray: rgba(46, 45, 45, 0.068);
  /* --nyc-gray: silver; */
  --brick-dk-black: #121213;
  --brick-lt-black: #100f10;
  --brick-dk-purple: hsla(272, 29%, 10%, 0.996);
  --brick-lt-purple: rgba(30, 23, 36, 0.996);

  --chic-gold: #b79001;
  --chic-lavender: #4b0082;
  --chic-pale: #ab94ee;
  --chic-bg: rgb(232, 221, 251);
  /* --chic-pale: #dcd0ff; */
  /* --bs-nav-link-hover-color: #ffffff; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  min-height: 100vh;
  width: 100%;
  font-family: var(--montserrant);
  font-size: 1em;
  background: #646464;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

html,
body {
  font-size: 1em;
  text-align: center;
  line-height: 1.5;
  /* prevent shift right on modal load */
  padding-right: 0px !important;
  position: relative !important;
  overflow-x: hidden !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
h1 {
  font-weight: 700;
}
main {
  align-items: center;
  width: 100%;
  height: auto;
}
.info-char {
  /* apostrophe fonts: urbanist or noto-sans-display*/
  font-family: var(--noto-sans-display);
}
/* hr {
  color: var(--chic-lavender);
  font-weight: bold;
} */

.chic {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner-wrapper {
  background-image: linear-gradient(335deg, #0f0f0f 23px, transparent 23px),
    linear-gradient(155deg, #000000 23px, transparent 23px),
    linear-gradient(335deg, rgb(15, 15, 15) 23px, transparent 23px),
    linear-gradient(155deg, #060404 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 26px 31px, 31px 6px;
  box-shadow: 5px 5px 10px #282828, -5px -5px 10px #363636;
}

/* .content-row {
  background-color: #fef5f5;
  box-shadow: 5px 5px 3px #060606, -5px -5px 3px #181818;
} */

.scroll-to-top {
  background: #b7a9e2 !important;
  color: #ffffff !important;
  border: 3px double var(--chic-lavender) important;
}

.scroll-to-top:hover {
  scale: 1.1;
}
