.contact-row {
  border: 5px double var(--chic-pale);
  background: linear-gradient(145deg, #0e0e0e, #101010);
  box-shadow: 5px 5px 3px #060606, -5px -5px 3px #181818;
}
.contact-holder {
  background: rgb(232, 221, 251);
  border: 4px solid #646464;
}

.ct-info {
  color: var(--nyc-black);
}
.icon-holder {
  width: 60px;
  height: 60px;
  border: 2px solid silver;
  border-radius: 50%;
  background: var(--chic-pale);
  margin-right: 20px;
  box-shadow: 5px 5px 22px #e3e3e3, -5px -5px 22px #ffffff;
}

.contact-form {
  border-radius: 5px;
  background: #c0c0c0;
  background: #646464;
  background: var(--chic-pale);
  box-shadow: 6px 6px 12px #666666, -6px -6px 12px #ffffff;
}

.form-success {
  color: #fff;
  background: var(--chic-lavender);
  border-radius: 5px;
  /* border: 3px solid white; */
  box-shadow: 6px 6px 12px #9682d1, -6px -6px 12px #c0a6ff;
}
.ct-location {
  width: 15px;
  height: 31px;
}
.ct-mobile {
  width: 15px;
  height: 31px;
}

input,
textarea {
  border: 1px solid #000;
  outline-color: none;
}

.err {
  font-size: 0.875em;
}

.sendBtn {
  border: none;
  outline: none;
  color: white;
  background: var(--chic-lavender);
  cursor: pointer;
  box-shadow: 7px 7px 14px #937fcd, -7px -7px 14px #c3a9ff;
}
.sendBtn:hover,
.sendBtn:focus,
.sendBtn:active {
  color: #ffffff;
  background: hsla(275, 100%, 25%, 70%);
}
